<template>
  <v-container fill-height fluid>
    <v-row align="center"
        justify="center">
        <v-col align="center">
          <v-progress-circular
            indeterminate
            color="#FF6465"
          ></v-progress-circular>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import localforage from '../plugins/localforage';

export default {
  metaInfo: {
    title: "Login",
  },
  name: "LoginPage",
  watch: {
    '$auth.loading': function () {
      if (this.$auth.isAuthenticated) {
        this.$router.push('/callback');
      } else {
        this.$auth.loginWithRedirect();
      }
    }
    
  },
  async mounted() {
    var isOnline = await localforage.getItem('onlineStatus');
      if (isOnline === true) {
        if (this.$auth.isAuthenticated) {
          this.$router.push('/callback');
        } else {
          this.$auth.loginWithRedirect();
        }
      } else {
        this.$router.push('/callback');
      }
  }

};
</script>

<style scoped></style>
